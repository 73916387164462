import { Address } from "wagmi";

export const selectedTokenInitialState = {
    decimals: 0,
    initiatedDeploymentFeeOnChainId: 0,
    initiatedDeploymentTxHash: "",
    name: "",
    networks: [],
    owner: "",
    params: "",
    salt: "",
    symbol: "",
    tokenMintChainId: 0,
    totalSupply: "",
};

export const selectOptionsInitialState = {
    label: "",
    value: null,
    icon: null,
    address: "0x00000000000000000000000" as Address,
    genericTokenAddress: "0x00000000000000000000000" as Address,
};

export const payloadABI = [
    {
        name: "amount",
        type: "uint256",
    },
    {
        name: "tokenDecimals",
        type: "uint8",
    },
    {
        name: "sourceTokenAddress",
        type: "bytes32",
    },
    {
        name: "sourceUserAddress",
        type: "bytes32",
    },
    {
        name: "sourceTokenChain",
        type: "uint256",
    },
    {
        name: "destTokenAddress",
        type: "bytes32",
    },
    {
        name: "destUserAddress",
        type: "bytes32",
    },
    {
        name: "destTokenChain",
        type: "uint256",
    },
];

export const payload721ABI = [
    {
        name: "uri",
        type: "string"
    },
    {
        name: "tokenId",
        type: "uint256"
    },
    {
        name: "sourceTokenAddress",
        type: "bytes32"
    },
    {
        name: "sourceUserAddress",
        type: "bytes32"
    },
    {
        name: "sourceTokenChain",
        type: "uint256"
    },
    {
        name: "destTokenAddress",
        type: "bytes32"
    },
    {
        name: "destUserAddress",
        type: "bytes32"
    },
    {
        name: "destTokenChain",
        type: "uint256"
    },
];