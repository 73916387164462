import { Box, Flex } from "components/Box";
import Button from "components/Button";
import Spinner from "components/SpinnerCircle";
import { Text } from "components/Text";
import { ChainArray, inputStyles } from "config/constants";
import {
    BridgeNFTResponse,
} from "config/types";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import {
    getAllNFTs,
} from "services/nft.service";
import {
    useAccount,
    useBalance,
    useNetwork,
    useProvider,
    useSigner,
    useSwitchNetwork,
} from "wagmi";
import useTheme from "../../hooks/useTheme";
import { InnerSection, MainContainer } from "../Bridge/styles";
import { NFTinput } from "./styles";
import NFTmodal from "components/NFTModal";
import {
    RoutingEngineFacets,
    catERC721,
} from "config/abi/catERC721";
import { BigNumber, ethers } from "ethers";
import { payload721ABI } from "../Bridge/constants/initial-states";
import { EVM_CHAIN_ID_TO_CONVENTION } from "config/constants/chains";
import Web3 from "web3";
import { useModal } from "widgets/Modal";
import BridgeModal from "../Bridge/components/BridgeModal";
import { getAxelarFee, ListenToLogs } from "../Bridge/utils";
import { handleDecimals, toastMessage } from "utils";

type SelectOptions = {
    label: string;
    value: number;
    icon: any;
    address: `0x${string}`;
    genericTokenAddress: `0x${string}`;
};

const BridgeNFT = () => {
    const { theme } = useTheme();
    const [supportedBlockChains, setSupportedBlockChains] = useState<Array<SelectOptions>>([]);
    const [selectedToChain, setSelectedToChain] = useState<SelectOptions>();
    const [selectedFromChain, setSelectedFromChain] = useState<SelectOptions>();
    const [nfts, setNfts] = useState<Array<BridgeNFTResponse>>([]);
    const [isFetchEstimates, setIsFetchEstimates] = useState<boolean>(false);
    const [selectedChainNetworks, setSelectedChainNetworks] = useState([])
    const [nftId, setNftId] = useState<number>(0)
    const { data: signer } = useSigner();
    const [estimatedValueInWei, setEstimatedValueInWei] = useState(null);
    const [isTransactionOccuring, setIsTransactionOccuring] = useState(false);
    const [txHash, setTxHash] = useState("");
    const [explorerLink, setExplorerLink] = useState("");
    const [bridgeEstimate, setBridgeEstimate] = useState<any>();

    const { Option } = components;
    const IconOption = (props) => (
        <Option className="chain-options" {...props}>
            <Box className="svg-icon" mr={'5px'}>{props.data.icon}</Box>
            {props.data.label}
        </Option>
    );

    const SingleValue = (props) => (
        <Flex alignItems={'center'}>
            <Box className="select-svg-icon" mt={'7px'} mr={'5px'}>{props.data.icon}</Box>
            {props.data.label}
        </Flex>
    );

    const { address, isConnected } = useAccount();
    const { chain } = useNetwork();
    const { switchNetworkAsync } = useSwitchNetwork();
    const provider: any = useProvider();

    const { data: balanceData } = useBalance({
        address,
        chainId: selectedFromChain?.value,
    });

    const isValid: boolean =
        isConnected && nftId &&
            selectedFromChain &&
            selectedToChain
            ? true
            : false;

    const [showBridgeModal, onDismissBridgeModal] = useModal(
        <BridgeModal
            handleDismiss={() => {
                setExplorerLink("");
                setTxHash("");
                setNftId(0);
                setSelectedToChain(null);
                onDismissBridgeModal();
            }}
            fromChain={selectedFromChain}
            toChain={selectedToChain}
            explorerLink={explorerLink}
            txHash={txHash}
        />,
        true
    );

    useEffect(() => {
        fetchNFTs();
    }, []);

    useEffect(() => {
        if (selectedChainNetworks) {
            const availableChains = selectedChainNetworks.map((x) => {
                return {
                    chainId: x.chainId,
                    address: x.address,
                    genericTokenAddress: x?.genericTokenAddress,
                };
            });
            const selectedNftChains = [];
            ChainArray.forEach((item) => {
                for (let chain of availableChains) {
                    if (chain.chainId === item.chainId) {
                        selectedNftChains.push({
                            value: item.chainId,
                            label: item.name,
                            icon: item.icon,
                            address: chain.address,
                            genericTokenAddress: chain.genericTokenAddress,
                        });
                    }
                }
                return selectedNftChains;
            });
            setSelectedFromChain(null);
            setSelectedToChain(null);
            setSupportedBlockChains(selectedNftChains);
        }
    }, [selectedChainNetworks]);

    useEffect(() => {
        if (chain?.id) {
            const defaultChain: SelectOptions = supportedBlockChains.find(data => data.value === chain?.id)
            setSelectedFromChain(defaultChain)
        }
    }, [nftId, chain])

    useEffect(() => {
        fetchEstimates();
    }, [isConnected, selectedFromChain, selectedToChain]);

    useEffect(() => {
        if (isValid === false) {
            setBridgeEstimate(undefined);
        }
    }, [isValid]);

    const fetchNFTs = async () => {
        try {
            const res = await getAllNFTs();
            const nftRes = res.nfts.map((collection) => {
                return {
                    baseUri: collection.baseUri,
                    name: collection.name,
                    ticker: collection.symbol,
                    networks: collection.networks,
                    owner: collection.owner,
                    imageUrl: collection.imageUrl,
                    tokenMintChainId: collection.tokenMintChainId
                };
            });
            setNfts(nftRes);
        } catch (err) {
            console.log(err);
            setNfts([]);
        }
    };

    const checkSwitchNetwork = async (choice: SelectOptions) => {
        if (chain && choice && chain?.id !== choice?.value) {
            try {
                await switchNetworkAsync(choice.value);
                setNftId(null)
            } catch (error) {
                toastMessage("User rejected the transaction.", "error");
                return;
            }
        }
        setSelectedFromChain(choice);
    }

    const getICATERC721Payload = () => {
        return {
            uri: `${nfts[0]?.baseUri}${nftId}`,
            tokenId: nftId,
            destTokenAddress: ethers.utils.hexZeroPad(selectedToChain?.address, 32),
            destTokenChain: EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
            destUserAddress: ethers.utils.hexZeroPad(address, 32),
            sourceTokenAddress: ethers.utils.hexZeroPad(
                selectedFromChain?.address,
                32
            ),
            sourceTokenChain: EVM_CHAIN_ID_TO_CONVENTION[selectedFromChain?.value],
            sourceUserAddress: ethers.utils.hexZeroPad(address, 32),
        }
    }

    const fetchEstimates = async () => {
        try {
            if (isValid) {
                setIsFetchEstimates(true);
                const contract = new ethers.Contract(selectedFromChain?.address, catERC721, signer);
                const routingEngineAddress = await contract.routingEngine();
                const protocolPriority = await contract.getProtocolPriority();
                const gasLimit = await contract.getGasLimit();

                const routingEngine = new ethers.Contract(
                    routingEngineAddress,
                    RoutingEngineFacets,
                    signer
                );

                const payload = getICATERC721Payload()
                const encodedPayload = ethers.utils.defaultAbiCoder.encode(
                    [
                        {
                            components: payload721ABI,
                            name: "catPayload",
                            type: "tuple",
                        },
                    ] as ethers.utils.ParamType[],
                    [payload]
                );

                const fee = await routingEngine.getEstimatedFee(
                    encodedPayload,
                    protocolPriority,
                    EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
                    ethers.utils.hexZeroPad(address, 32),
                    ethers.utils.hexZeroPad(selectedToChain?.address, 32),
                    gasLimit
                );
                let estimatedWei: BigNumber = fee.add(fee.mul(10).div(100));
                if (protocolPriority.map((p) => p.toString()).includes("1")) {
                    estimatedWei = await getAxelarFee(estimatedWei, selectedFromChain?.value, selectedToChain?.value);
                }
                setEstimatedValueInWei(estimatedWei);
                setBridgeEstimate(ethers?.utils?.formatEther(estimatedWei?.toString()));
                setIsFetchEstimates(false);
            }
        } catch (error: any) {
            toastMessage(error.message, "error")
            setBridgeEstimate(undefined);
            setIsFetchEstimates(false);
        }
    };

    const signTransaction = async () => {
        if (chain?.id !== selectedFromChain.value) {
            const network = await switchNetworkAsync(selectedFromChain.value)
                .then((res) => {
                    sendTx();
                })
                .catch(() => {
                    toastMessage("Error while changing network", "error");
                });
        } else {
            sendTx();
        }
    };

    const approveTransaction = async (sourceChain: SelectOptions) => {
        const contract = new ethers.Contract(
            sourceChain.genericTokenAddress,
            catERC721,
            signer
        );
        try {
            const isApprovedForAll = await contract.isApprovedForAll(address, selectedFromChain?.address)
            if (isApprovedForAll) { return true }
            else {
                const approval = await contract.setApprovalForAll(
                    sourceChain.address,
                    true
                )

                await approval.wait(1);
                return true;
            }
        } catch (error: any) {
            toastMessage("User rejected the transaction.", "error")
            setIsTransactionOccuring(false);
            return false;
        }
    };

    const sendTx = async () => {
        setIsTransactionOccuring(true);
        try {
            const web3 = new Web3(provider.connection.url);

            if (selectedFromChain?.genericTokenAddress !== null) {
                const response = await approveTransaction(selectedFromChain);
                if (!response) return;
            }
            let contract = new ethers.Contract(selectedFromChain?.address, catERC721, signer);

            if (estimatedValueInWei === null) {
                setIsTransactionOccuring(false);
                return;
            }

            const estimates = await contract.bridgeOut(
                nftId,
                EVM_CHAIN_ID_TO_CONVENTION[selectedToChain?.value],
                ethers.utils.hexZeroPad(address, 32),
                {
                    value: estimatedValueInWei.toString(),
                }
            );
            await estimates.wait(2);

            let tx = await web3.eth.getTransactionReceipt(estimates?.hash);
            const { txLink, status, hash } = await ListenToLogs(tx, estimates, provider);
            setTxHash(hash);
            setExplorerLink(txLink);
            setIsTransactionOccuring(status);
        } catch (error: any) {
            setIsTransactionOccuring(false);
            if (error.code === "ACTION_REJECTED") {
                toastMessage("User rejected the transaction.", "error")
                return
            }
            toastMessage("The selected chain is not supported by the underlying bridge.", "error")
        }
    }

    useEffect(() => {
        if (explorerLink) {
            showBridgeModal();
        }
    }, [explorerLink]);

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const openModal = () => {
        if (isConnected) {
            setModalIsOpen(true)
            setNftId(null)
        } else {
            toast.error(`Please connect your wallet.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                theme: "dark",
            });
        }
    }

    return (
        <MainContainer>
            <InnerSection>
                {<NFTmodal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} setNftId={setNftId} nfts={nfts} setSelectedChainNetworks={setSelectedChainNetworks} />}
                <Flex justifyContent={"space-around"}>
                    <Text
                        fontFamily={theme.fonts.primary}
                        fontWeight={theme.fonts.semiBold}
                        fontSize={"32px"}
                    >
                        Bridge
                    </Text>
                </Flex>
                <Flex justifyContent={"space-around"} mt={"21px"}>
                    <Text
                        fontFamily={theme.fonts.primary}
                        fontStyle={"normal"}
                        fontWeight={theme.fonts.light}
                        fontSize={"14px"}
                    >
                        Migrate your NFT across chains
                    </Text>
                </Flex>
                <Flex mt={"10px"}>
                    <Flex flexDirection={"column"} width={"100%"} mt={"20px"}>
                        <NFTinput
                            onClick={() => openModal()}
                        >
                            <Flex ml={'5px'}>
                                {nftId ? `NFT ID: ${nftId}` : 'Select NFT'}
                            </Flex>
                        </NFTinput>
                    </Flex>
                </Flex>
                <Flex flexWrap={"wrap"}>
                    <Flex mt={"10px"} width={"50%"} flexDirection={"column"}>
                        <Flex mt={"16px"} ml={"12px"} mb={"8px"}>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                fontSize={"14px"}
                            >
                                From
                            </Text>
                        </Flex>
                        <Flex width={"90%"}>
                            <Select
                                isClearable
                                className="select-main-container"
                                name="form-field-name"
                                placeholder={"Select"}
                                options={supportedBlockChains.filter(
                                    (x) => x.value !== selectedToChain?.value
                                )}
                                value={selectedFromChain}
                                onChange={(choice) => checkSwitchNetwork(choice)}
                                components={{
                                    Option: IconOption,
                                    SingleValue,
                                    IndicatorSeparator: () => null,
                                }}
                                styles={inputStyles}
                            />
                        </Flex>
                    </Flex>

                    <Flex mt={"10px"} width={"50%"} flexDirection={"column"}>
                        <Flex mt={"16px"} ml={"10px"} mb={"8px"}>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                fontSize={"14px"}
                            >
                                To
                            </Text>
                        </Flex>
                        <Flex>
                            <Select
                                isClearable
                                className="select-main-container"
                                name="form-field-name"
                                options={supportedBlockChains.filter(
                                    (x) => x.value !== selectedFromChain?.value
                                )}
                                placeholder={"Select"}
                                value={selectedToChain}
                                onChange={(choice) => setSelectedToChain(choice)}
                                components={{
                                    Option: IconOption,
                                    SingleValue,
                                    IndicatorSeparator: () => null,
                                }}
                                styles={inputStyles}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex flexWrap={"wrap"}>
                    <Flex mt={"10px"} width={"100%"} flexDirection={"column"}>
                        <Flex
                            mt={"16px"}
                            mb={"0px"}
                            justifyContent={"space-between"}
                        >
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                color={theme.colors.text}
                                fontSize={"14px"}
                            >
                                Required Balance:
                            </Text>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                fontSize={"14px"}
                                color={
                                    isValid
                                        ? parseFloat(balanceData?.formatted) > bridgeEstimate
                                            ? theme.colors.success
                                            : theme.colors.failure
                                        : theme.colors.textDisabled
                                }
                            >
                                {isConnected && isFetchEstimates ? (
                                    <Spinner radius={8} />
                                ) : (isConnected &&
                                    isValid && bridgeEstimate) ? (
                                    `${handleDecimals(+bridgeEstimate)} ${balanceData?.symbol}`
                                ) : (
                                    "-"
                                )}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex mt={"8px"} width={"100%"} flexDirection={"column"}>
                        <Flex
                            mt={"16px"}
                            mb={"0px"}
                            justifyContent={"space-between"}
                        >
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                color={theme.colors.text}
                                fontSize={"14px"}
                            >
                                Available Balance:
                            </Text>
                            <Text
                                fontFamily={theme.fonts.primary}
                                fontWeight={theme.fonts.light}
                                color={theme.colors.textDisabled}
                                fontSize={"14px"}
                            >
                                {isConnected && isFetchEstimates ? (
                                    <Spinner radius={8} />
                                ) : isConnected && balanceData?.formatted ? (
                                    `${parseFloat(balanceData?.formatted).toFixed(4)} ${balanceData.symbol
                                    }`
                                ) : (
                                    "-"
                                )}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex justifyContent={"space-around"} mt={"21px"}>
                    <Box width={'100%'}>
                        <Button
                            height={"44px"}
                            width={"478px"}
                            type={"button"}
                            variant={"tertiary"}
                            onClick={signTransaction}
                            disabled={
                                parseFloat(balanceData?.formatted) <
                                parseFloat(
                                    bridgeEstimate
                                ) ||
                                bridgeEstimate === undefined ||
                                isValid === false ||
                                isTransactionOccuring
                            }
                        >
                            <Flex justifyContent={"center"}>
                                <Text
                                    fontWeight={theme.fonts.semiBold}
                                    fontSize={"14px"}
                                    ml={"6px"}
                                >
                                    {isTransactionOccuring ? (
                                        <Spinner radius={8} />
                                    ) : (
                                        "Migrate"
                                    )}
                                </Text>
                            </Flex>
                        </Button>
                    </Box>
                </Flex>
            </InnerSection>
        </MainContainer>
    );
};

export default BridgeNFT;
