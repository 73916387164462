import { client } from "./token.service";
import { url } from "./urls";

export const getExplorerLink = async (explorer: "ccip" | "LayerZero", hash: string) => {
    return client.post(
        `${url.explorer}`, {
        explorer,
        hash
    }
    )
}

export const getAxelarEstimatedFee = async (sourceChainID: string, dstChainID: string, sourceChainToken: string) => {
    return client.post(
        `${url.axelarBridgeEstimates}`, {
        sourceChainID,
        dstChainID,
        sourceChainToken,
        environment: process.env.REACT_APP_CHAINS_ENV
    }
    )
}