import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Flex } from "components/Box";
import Arrow from "../../assets/arrow.svg";
import DisabledArrow from "../../assets/disabled-arrow.svg";
import { Switch, SwitchToggle } from "components/Views/DeployTokens/styles";
import { Label } from "./styles";

type TSortableItem = {
  handleSelect: (e: any) => void;
  handleMove: (e: "up" | "down") => void;
  isFirst: boolean;
  isLast: boolean;
  checked: boolean;
  id: string;
  priority: number;
  disabled?: boolean
};

export function SortableItem({
  handleSelect,
  isFirst,
  isLast,
  id,
  priority,
  handleMove,
  checked,
  disabled
}: Readonly<TSortableItem>) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled: !checked || disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Flex
        alignItems={"center"}
        mt="10px"
        justifyContent="space-between"
        pr="15px"
        style={{ cursor: "pointer", opacity: disabled ? "0.5" : "" }}
        background="#201D1D"
        borderRadius="8px"
        height="48px"
        color="#FFFFFF"
      >
        <Flex alignItems="center">
          <Switch
            isDisabled={false}
            onClick={() => {
              if (disabled) {
                return
              }
              handleSelect(!checked);
            }}
            isChecked={checked}
          >
            <SwitchToggle state={!checked} />
            <SwitchToggle state={checked} />
          </Switch>
          <Label fw={300} checked={checked}>
            {id}
          </Label>
        </Flex>
        <Flex alignItems={"center"}>
          <Flex
            justifyContent="center"
            alignItems="center"
            width="30px"
            margin="0 10px 1px 0"
            height="30px"
            borderRadius="7px"
            border="1px solid #AAAAAA"
            background="#2E2B2C"
          >
            {priority || "-"}
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            style={{ pointerEvents: !checked ? "none" : "all" }}
            height="25px"
          >
            <img
              src={!checked || isFirst ? DisabledArrow : Arrow}
              style={{ rotate: "180deg" }}
              onClick={() => {
                if (disabled) {
                  return
                }
                handleMove("up")
              }}
              alt="arrow-icon"
            />
            <img
              src={!checked || isLast ? DisabledArrow : Arrow}
              onClick={() => {
                if (disabled) {
                  return
                }
                handleMove("down")
              }}
              alt="arrow-icon"
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}
