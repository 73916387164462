export enum SupportedChainId { // for Non EVM we are putting these Chain IDs to be in negative.
  TERRA = 202, // for Non EVM we are putting these Chain IDs to be in negative.
  ETHEREUM = 1,
  POLYGON = 137,
  BINANCE = 56,
  AVAX = 43114,
  FANTOM = 250,
  FUJI = 43113,
  GOERLI = 5,
  BINANCE_TESTNET = 97,
  FANTOM_TESTNET = 4002,
  MUMBAI = 80001,
  ARBITRUM = 42161,
  OPTIMISM = 10,
  ARBITRUM_TESTNET = 421613,
}

export const EVM_CHAIN_ID_TO_TICKER_AXELAR = {
  [SupportedChainId.ETHEREUM]: "ETH",
  [SupportedChainId.GOERLI]: "ETH",
  [SupportedChainId.AVAX]: "AVAX",
  [SupportedChainId.FUJI]: "AVAX",
  [SupportedChainId.POLYGON]: "MATIC",
  [SupportedChainId.MUMBAI]: "MATIC",
  [SupportedChainId.BINANCE]: "BNB",
  [SupportedChainId.BINANCE_TESTNET]: "BNB",
  [SupportedChainId.FANTOM]: "FTM",
  [SupportedChainId.FANTOM_TESTNET]: "FTM",
  [SupportedChainId.ARBITRUM]: "ETH",
  [SupportedChainId.ARBITRUM_TESTNET]: "AGOR",
};


export enum ConventionChainIds {
  ETHEREUM = 1,
  GOERLI = 2,
  SEPOLIA = 3,
  FUJI = 4,
  ARBITRUM = 5,
  POLYGON = 6,
  BINANCE = 7,
  AVAX = 8,
  FANTOM = 9,
  TRON = 10,
  BSC_TESTNET = 11,
  FANTOM_TESTNET = 12,
  POLYGON_MUMBAI = 13,
  ARBITRUM_GOERLI = 14,
  OPTIMISM = 15,
  CELO_ALFAJORES = 16,
}

export const EVM_CHAIN_ID_TO_CONVENTION = {
  [SupportedChainId.ETHEREUM]: ConventionChainIds.ETHEREUM,
  [SupportedChainId.GOERLI]: ConventionChainIds.GOERLI,
  [SupportedChainId.AVAX]: ConventionChainIds.AVAX,
  [SupportedChainId.FUJI]: ConventionChainIds.FUJI,
  [SupportedChainId.POLYGON]: ConventionChainIds.POLYGON,
  [SupportedChainId.MUMBAI]: ConventionChainIds.POLYGON_MUMBAI,
  [SupportedChainId.BINANCE]: ConventionChainIds.BINANCE,
  [SupportedChainId.BINANCE_TESTNET]: ConventionChainIds.BSC_TESTNET,
  [SupportedChainId.FANTOM]: ConventionChainIds.FANTOM,
  [SupportedChainId.FANTOM_TESTNET]: ConventionChainIds.FANTOM_TESTNET,
  [SupportedChainId.ARBITRUM]: ConventionChainIds.ARBITRUM,
  [SupportedChainId.ARBITRUM_TESTNET]: ConventionChainIds.ARBITRUM_GOERLI,
  [SupportedChainId.OPTIMISM]: ConventionChainIds.OPTIMISM,
};


export const EVM_CHAIN_TO_AXELAR_NAMES = {
  [SupportedChainId.ETHEREUM]: "Ethereum",
  [SupportedChainId.ARBITRUM]: "arbitrum",
  [SupportedChainId.POLYGON]: "Polygon",
  [SupportedChainId.AVAX]: "Avalanche",
  [SupportedChainId.BINANCE]: "binance",
  [SupportedChainId.FANTOM]: "Fantom",
  [SupportedChainId.FUJI]: "Avalanche",
  [SupportedChainId.MUMBAI]: "Polygon",
  [SupportedChainId.BINANCE_TESTNET]: "binance",
  [SupportedChainId.GOERLI]: "ethereum-2",
  [SupportedChainId.OPTIMISM]: "optimism",
  [SupportedChainId.FANTOM_TESTNET]: "Fantom",
  [SupportedChainId.ARBITRUM_TESTNET]: "arbitrum",
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.ETHEREUM]: "Ethereum",
  [SupportedChainId.TERRA]: "Terra",
  [SupportedChainId.POLYGON]: "Polygon",
  [SupportedChainId.BINANCE]: "Binance",
  [SupportedChainId.FANTOM]: "Fantom",
  [SupportedChainId.AVAX]: "Avax",
  [SupportedChainId.FUJI]: "Fuji",
  [SupportedChainId.GOERLI]: "Goerli",
  [SupportedChainId.BINANCE_TESTNET]: "Binance",
  [SupportedChainId.FANTOM_TESTNET]: "Fantom testnet",
  [SupportedChainId.MUMBAI]: "Mumbai",
  [SupportedChainId.ARBITRUM]: "Arbitrum",
  [SupportedChainId.OPTIMISM]: "Optimism",
  [SupportedChainId.ARBITRUM_TESTNET]: "Arbitrum testnet",
  4: "rinkeby",
};

export const MORALIS_CHAIN_IDs = {
  [SupportedChainId.ETHEREUM]: "0x1",
  [SupportedChainId.POLYGON]: "0x89",
  [SupportedChainId.BINANCE]: "0x38",
  [SupportedChainId.FANTOM]: "0xfa",
  [SupportedChainId.AVAX]: "0xa86a",
  [SupportedChainId.FUJI]: "0xa869",
  [SupportedChainId.GOERLI]: "0x5",
  [SupportedChainId.BINANCE_TESTNET]: "0x61",
  [SupportedChainId.FANTOM_TESTNET]: "0xfa",
  [SupportedChainId.MUMBAI]: "0x13881",
  [SupportedChainId.ARBITRUM]: "0xa4b1",
  [SupportedChainId.OPTIMISM]: "0xa",
  [SupportedChainId.ARBITRUM_TESTNET]: "0x66eed",
}

